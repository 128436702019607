import Head from 'next/head';
import sanitizeHtml from 'sanitize-html';
import defaultImage from '../../../public/images/user_referral_image.png';

import { CANONICAL_ROOT_URL } from '../../constants/constants';
import { isDevelopment } from '../../helpers/environment/isDevelopment';
import { isTesting } from '../../helpers/environment/isTesting';

interface IProps {
  title?: string;
  image?: string;
  description?: string;
  robots?: string;
  ogTitle?: string;
  link?: string;
  schema?: string;
  sanitizeSchema?: boolean;
  useDefaultImage?: boolean;
}

const getValidatedLink = (link: string): string => {
  const absoluteURL = link.startsWith('http');
  const validatedLink = absoluteURL ? link : `${CANONICAL_ROOT_URL}${link}`;
  if (absoluteURL && !isDevelopment() && !isTesting() && !validatedLink.includes('https://')) {
    throw new Error(`MetaTags link defined as an unsecure URL: ${validatedLink}`);
  }
  return validatedLink;
};

/**
 * Component for setting meta tags in the head of the document.
 *
 * @param {IProps} props - The properties for the MetaTags component.
 * @param {string} [props.title] - The title of the page. used for <title> and as backup for <og:title>.
 * @param {string} [props.image] - The URL of the image to be used in the meta tags for <og:image>.
 * @param {string} [props.description] - The description of the page. Used for <description> and <og:description>.
 * @param {string} [props.robots] - The robots meta tag content.
 * @param {string} [props.ogTitle] - The Open Graph title of the page.
 * @param {string} [props.link] - The canonical URL of the page. Used also for <og:url>.
 * @param {string} [props.schema] - The JSON-LD schema to be included in the head.
 * @param {string} [props.sanitizeSchema] - Flag if the schema should be sanitized before being included in the head. Defaults to true
 *                                          But in some cases the sanitizer will transform html tags which then lead to malformed schema.
 *                                          In those cases the flag should be set to false and the schema should be sanitized before being transformed to JSON.
 * @param {boolean} [props.useDefaultImage=false] - Flag to use the default image if no image is provided.
 *                                                  If set to false and no image is provided the browser will choose an image.
 *
 * @returns {JSX.Element} The meta tags to be included in the head of the document.
 *
 * @throws {Error} If the provided link is an unsecure URL.
 */
export const MetaTags = ({
  title,
  image,
  description,
  robots,
  link,
  schema,
  sanitizeSchema = true,
  ogTitle,
  useDefaultImage = true,
}: IProps) => {
  const validatedLink = link && getValidatedLink(link);

  const imageSrc = image ?? (useDefaultImage && defaultImage.src);

  return (
    <Head>
      {schema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: sanitizeSchema ? sanitizeHtml(schema) : schema }}
        />
      )}
      {title && <title>{title}</title>}
      {(ogTitle ?? title) && <meta property="og:title" content={ogTitle ?? title} />}
      {imageSrc && <meta property="og:image" content={imageSrc} />}
      {description && <meta property="og:description" content={description} />}
      {description && <meta name="description" content={description} />}
      {robots && <meta name="robots" content={robots} />}
      <meta property="og:type" content="website" />
      {validatedLink && <meta property="og:url" content={validatedLink} />}
      {validatedLink && <link rel="canonical" href={validatedLink} />}
    </Head>
  );
};
